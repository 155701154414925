.Matters-Header-Segment{
    /* background-color: #F4911E !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
.Matters-Segment-Generic{
    max-height: 128px;
    min-height: 128px;
    overflow-y: auto;
    border-style: none !important;
}
.Matters-Sub-Grid{
    /* border: 1px solid black; */
    margin-top: 5px;
    margin-bottom: 5px;
}
.Matters-Grid-Label{
    cursor: default;
    /* background-color: #F4911E !important; */
    background-color: #00395A !important;
    color: #00395A !important;
}
.Matters-Grid-Segment-Completed{
    background-color: rgb(144,238,144, 0.7) !important;
}
.Matters-Grid-Segment-Outstanding{
    background-color: rgb(240,128,128, 0.6) !important;
}
.Matters-Div-Milestone{
    min-height: 57px; 
    text-align: center;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif; 
}
.Matters-Grid-Row{
    padding-bottom: 0px !important;
}
.Matters-TitleText-Blue{
    color: #00395A !important;
}
.Matters-Doc-Link{
    cursor: pointer;
}
.Matters-Client-Segment-Hidden{
    display: none;
}
.Matters-Client-Segment-Hidden-mobile{
    display: none;
}
.Matters-Client-Segment-Hidden-postapt{
    display: none;
}
.rbc-time-view .rbc-row {
    min-height: 0px;
}  
.rbc-time-view .rbc-row div:nth-child(1) {
    /* height: 0px !important; */
}
.noDocsHide{
    display: none;
}
.ClientApptLocationChoiceLabel{
    margin-right: 15px;
}
.ClientLocationChoiceActive{
    color: white !important;
    background-color: #2283c9 !important;
}
