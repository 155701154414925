  
.RNG-div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh !important; /* Ensure the container takes up the full viewport height */
    width: 100% !important; /* Ensure the container takes up the full width of the parent */
    text-align: center !important; /* Center the text horizontally */
  }
  
.RNGNumber {
    font-size: 500px; /* Corrected font-size syntax */
  }

.RNG-hidden{
    display: none !important;
  }