
.Reset-Segment-Wrapper{
    margin-left: auto;
    margin-right: auto;
}
.Reset-Segment-Table-Main{
    margin-left: auto;
    margin-right: auto;
}
.Reset-Segment-Table-Column{
    width: 200px;
    /* text-align: right; */
}
.Reset-Segment-Table-Label{    
    /* width: 119px;
    text-align: right; */
    margin-left: 10px;
}
.Reset-Segment-Input-Pass1{
    width: 250px;
    margin-left: -12px;
}
.Reset-Segment-Table-Column{
    width: 250px !important;
}
.Reset-Segment-Table-TD-Pass2{
    display: inline-flex !important;
    align-items: center;
    margin-left: 10px;
}
.Reset-Segment-Input-Pass2{
    width: 250px;
    border-radius: 5px;
    border: 1px solid red;
    margin-left: 25px;

}
.Reset-Segment-Icon-Pass2-Bad{
    color: red;    
}
.Reset-Segment-Icon-Pass2-Good{
    color: green;    
}
.Button-Submit{
    background-color: #00395A !important;
    color: white !important;
    float: right;
}