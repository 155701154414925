.portalCard_visible .card{
    background-color: #dff0ff !important;
    border: 1px solid #2185d0 !important;
}
.portalCard_hidden .card{
    background-color: #dff0ff !important;
    border: 1px solid #2185d0 !important;
    display: none;
}

.portalCards_Header_Hidden{
    display: none;
}