.has-text-centered{
    text-align: center;
}
.Appts-Grid-Option{
    display: none;
}
.Appts-Div-Option{
    display: inline-block;
    margin-bottom: -8px;
}
.Appts-Header-Option{
    display: inline;
}
.Appt-Modal-Booked{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.rbc-time-header{
    height: 18px !important;
}
.rbc-time-view .rbc-row div:nth-child(1) {
    /* height: 0px !important; */
}
.Div-Matter-Dropdown{
    margin: auto;
    width: 400px;
    /* border: 1px solid red; */
}
.Div-Matter-Dropdown-Inner{
    width: 400px;
}
.H2-Selected-Override{
    color: red !important;
}