.AdminIQ-Table{
    border-style: none !important;
    max-width: 100%;
}
.AdminIQ-Segment-Generic{
    min-height: 60px;
    max-height: 60px;
    max-width: 550px !important;
    /* overflow-y: auto; */
    border-style: none !important;
}
.AdminIQ-Table-Heading{
    float: left;
    margin-left: 5px;
    max-width: 50px;
}
.ReactTable .rt-th{
    width: 20% !important;
    max-width: 20% !important;
}
.AdminIQ-Cell{
    font-size: 1em;
}
.AdminIQ-Button-Goto{
    margin-top: -10px !important;
}