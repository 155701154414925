.App {
  /* text-align: center; */
  width: 95% !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* #00395A */
.Navbar {
  /* background-color: #F4911E !important; */
  /* color: #00395A !important; */
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
}
.button-as-link{
  background:none!important;
   border:none; 
   padding:0!important;
  
  /*optional*/
  font-family:arial,sans-serif; /*input has OS specific font-family*/
   color:#069;
   cursor:pointer;
}

