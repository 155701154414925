.Matters-Header-Segment {
  /* background-color: #f4911e !important; */
  /* color: #00395a !important; */
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
}
.Matters-Segment-Generic {
  max-height: 128px;
  min-height: 128px;
  overflow-y: auto;
  border-style: none !important;
}
.Matters-Cell-Center {
  text-align: center;
}
.Matters-Cell-SizeMed {
  font-size: 1.4em;
}
.Matters-Cell-SizeSmall {
  font-size: 1.2em;
}
.Matters-Header-Ref {
  margin-top: -5px !important;
  margin-bottom: 5px !important;
}
.Matters-Cell-NameIFARef {
  font-size: 1.4em;
}
.Matters-Cell-Address {
  font-size: 1em;
  margin-top: -15px;
}
.Matters-Cell-Date {
  font-size: 1.4em;
  margin-top: -10px;
  width: 100%;
  display: inline-block;
}
.Matters-CurrentStatus-Cell {
  /* background-color: #f4911e !important; */
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
}
.Matter-P-Notes {
  max-width: 100%;
  white-space: normal !important;
}
.Matters-Table-Heading {
  text-align: left;
}
/* .ReactTable .rt-th{
  min-width: 25% !important;
} */
.Matters-Button-History {
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
  float: right;
}
.Matters-Table {
  border-style: none !important;
}
.Matters-Table .rt-th{
  min-width: 25% !important;
}
.ReactTable .rt-td {
  border-right: 0 !important;
}
.ReactTable .rt-tr-group {
  border-bottom: 0 !important;
}
.ReactTable .rt-thead {
  margin-bottom: 5px;
  color: #00395a !important;
  box-shadow: none !important;
  background-color: #f3f4f5;
  border-radius: 5px;
}
.Matters-Icon-History {
  margin-left: -10px !important;
}
.Matters-Sub-Grid {
  /* border: 1px solid black; */
  margin-top: 5px;
  margin-bottom: 5px;
}
.Matters-Sub-Column {
  text-align: center;
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
}
.Matters-Grid-Row {
  padding-bottom: 0px !important;
}
.Matters-Table-Column {
  width: 25% !important;
}
.ReactTable .rt-expandable {
  width: 25% !important;
  max-width: 25% !important;
}
.ReactTable .rt-th {
  width: 25% !important;
  max-width: 25% !important;
}
.Matters-Search-Box {
  width: 100%;
}
.Matters-Button-Docs {
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
  float: right;
  margin-top: -20px !important;
}
.Matters-Modal-Docs {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 15% !important;
  margin-bottom: auto !important;
}
.Matters-Button-AmendNotes {
  float: right;
  background-color: lightgreen !important;
}
.Matters-Button-AmendNotes-Hidden {
  display: none !important;
}
.novexNotesCancelButtonCSS{
  /* float: left; */
  margin-bottom: 5px;
}
.Matters-Modal-AmendNotes {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 15% !important;
  margin-bottom: auto !important;
}
.Matters-TextArea-AmendNotes {
  margin-left: 15px;
  margin-right: 15px;
  width: 96%;
  max-width: 96%;
  min-width: 96%;
  min-height: 100px;
  border-radius: 5px;
}
.Matter-Div-NovexNotes-Parent {
  margin-top: 7%;
  vertical-align: middle !important;
}
.Matters-Button-NovexNotes {
  /* float: right; */
  display: block !important; 
  margin: auto !important;
  
  /* background-color: #f4911e !important; */
  background-color: var(--EquiMainColour) !important;
  color: var(--EquiTextColour) !important;
}
.Matters-Button-NovexNotes-Hidden {
  display: none !important;
}
.Matters-Button-CancelNotes {
  float: left;
}
.Matters-Notes-CharsRemaining {
  float: right;
  margin-right: 20px;
}
.Matters-Header {
  display: inline;
}
.Matters-ButtonGlossary {
  float: right;
}
.Matters-CaseType {
  float: right !important;
  margin-top: 0px;
  font-size: 1.3em;
}
.Matters-Button-Book {
  float: right;
}
.Matters-Hidden {
  display: none !important;
}
.Matters-NovexNotes-TopOffset{
  margin-top: 5% !important;
}
.Matters-NovexNotes-Paragraph{
  margin-top: 15px;
}
.Matters-Button-HoldReason {
  float: right;
  background-color: #f84242 !important;
}
