:root{
    --EquiBlue: #00395A;
    --EquiOrange: #F4911E;
    --EquiDarkOrange: #e58110;
    --EquiMainColour: var(--EquiBlue);
    --EquiTextColour: white;
}
.Common-Segment-Label{
    /* background-color: #F4911E !important; */
    /* background-color: #00395A !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour);
    color: var(--EquiTextColour);
}