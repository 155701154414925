.Settings-Segment-Wrapper{    
    /* width: 95% !important; */
    margin-left: auto;
    margin-right: auto;    
}
.Settings-Header-Segment{
    /* background-color: #F4911E !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
@media (min-width: 768px) {
    .Settings-Div-Email{
        display: inline-flex;
        width: 40% !important;
    }
}
@media (max-width: 768px) {
    .Settings-Div-Email{
        width: 100% !important;
    }
}
@media (min-width: 768px) {
    .Settings-Div-Password{
        display: inline-flex;
        width: 40% !important;
    }
}
@media (max-width: 768px) {
    .Settings-Div-Password{
        width: 100% !important;
    }
}
.Settings-Input-Success{
    border: 1px solid green !important;
    background-color: #c7d8be !important;
    box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent !important;
}
.Settings-Grid{
    max-width: 90%;
}
