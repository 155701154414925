.IQ-backButton{
    float: left;
}
.IQ-forwardButton{
    float: right;
}
.IQ-Grid{
    width: inherit !important;
}
.IQ-StepSegment-Hide{
    display: none;
}
.IQ-Steps{
    width: inherit !important;
    max-width: inherit !important;
}
@media (max-width: 1495px) {
    .IQ-Step-Icon{
        display: none !important;
    }
}
@media (max-width: 1235px) {
    .IQ-Step-Title{
        font-size: 13px !important;
    }
}
.IQ-checkboxDiv{
    display: inline-flex !important;
    align-items: center;
}
.IQ-checkbox{
    width: 25px;
    height: 25px;    
}
@media (min-width: 1510px) {
    .IQ-Label{
        /* background-color: #F4911E !important; */
        /* color: #00395A !important; */
        background-color: var(--EquiMainColour) !important;
        color: var(--EquiTextColour) !important;
        font-size: 15px !important;
        z-index: 0;
        /* display: inline !important; */
    }
}
@media (min-width: 1370px) and (max-width: 1509px) {
    .IQ-Label{
        /* background-color: #F4911E !important; */
        /* color: #00395A !important; */
        background-color: var(--EquiMainColour) !important;
        color: var(--EquiTextColour) !important;
        font-size: 13px !important;
        z-index: 0;
    }
}
@media (min-width: 1300px) and (max-width: 1369px) {
    .IQ-Label{
        /* background-color: #F4911E !important; */
        /* color: #00395A !important; */
        background-color: var(--EquiMainColour) !important;
        color: var(--EquiTextColour) !important;
        font-size: 12px !important;
        z-index: 0;
    }
}
@media (max-width: 1300px) {
    .IQ-Label{
        /* background-color: #F4911E !important; */
        /* color: #00395A !important; */
        background-color: var(--EquiMainColour) !important;
        color: var(--EquiTextColour) !important;
        font-size: 11px !important;
        z-index: 0;
    }
}
.IQ-Hidden{
    display: none;
}
.IQ-Form-Field-Xs{
    display: inline-flex;
        width: 25% !important;
}
.IQ-Form-Field-Sm{
    display: inline-flex;
        width: 50% !important;
}
.IQ-Form-Field-Md{
    display: inline-flex;
        width: 75% !important;
}
.IQ-Form-Field-Lg{
    display: inline-flex;
        width: 100% !important;
}
.IQ-FileUploader-Button{
    cursor: pointer;
    pointer-events: none;
    /* tells the browser to ignore button for all MouseEvents (doesn't affect :hover, etc) so the click hits the containing label instead */
}
.IQ-FileUploader-Input{    
    display: none !important;
}
.IQ-FileUploader-Label{
    max-width: auto;
    cursor: pointer;
}
.IQ-FileUploader-Name{
    width: 150px !important;
}
.IQ-Calendar{
    max-width: 200px !important;
    float: left;
    margin-top: -15px !important;
}
.IQ-Calendar-ParentDiv{
    white-space: nowrap;
    overflow-x: auto;
}
.calendar-icon{
    margin-top: -15px !important;
    transform: none !important;
}
.IQ-CalendarIcon{
    color: #F4911E;
    margin-top: -15px !important;
    margin-left: 7px !important;
}
.IQ-CalendarIcon:hover{
    color: lightgrey;
    margin-top: 0px !important;
    margin-left: 7px !important;
}
.IQ-Calendar-Label{
    margin-top: 7px !important;
    float: left;
    margin-right: 23px !important;
}
.IQ-BoldBlueHeader{
    color: #00395A !important;
    margin-top: 0px;    
}
.IQ-Segment{
    background-color: #244e66 !important;
    border: none !important;
}
.IQ-DivInline{
    display: inline !important;    
}
.or{
    z-index: 0 !important;
}
.IQ-Warning{
    color: red;
}
.IQ-Modal{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 2% !important;
    margin-bottom: auto !important;
    width: 95% !important;
}
.IQ-Modal-Grid{
    background-color: aquamarine;
}
.IQ-Modal-Grid-Column{
    background-color: olivedrab;
}
.IQ-Modal-Div-Lenders{
    float: right !important;
}
.IQ-Header{
    color: white !important;
}
.IQ-Segment-TopMargin{
    margin-top: 15px !important;
}
.IQ-Segment-BottomNegMargin{
    margin-bottom: -15px !important;
}
.IQ-Modal-Message{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 5% !important;
    margin-bottom: auto !important;
    max-width: 80%;
    white-space: pre-wrap;
}
.IQ-Segment-Header-Align{
    display: inline !important;
}
.IQ-Segment-Min{
    min-height: 56px;
}
.IQ-Button-Plus{
    width: 35px;
}
.IQ-Icon-Offset{
    margin-left: -8px !important;
    
}
.IQ-Input-SortCode-Left{
    /* padding: 0px !important; */
    padding-left: 8px !important;
    padding-right: 7px !important;
    width: 25px;
    max-width: 25px;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    margin-right: -1px !important;
    /* height: 28px !important;  */
}
.IQ-Input-SortCode-Right{
    /* padding: 0px !important; */
    padding-left: 8px !important;
    padding-right: 2px !important;
    width: 25px;
    max-width: 25px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}
.IQ-Font-SortCode{
    /* vertical-align: text-bottom !important; */
    margin-top: 8px !important;
}
.IQ-Input-UseOfFunds{
    margin-top: 5px !important;
}
.IQ-Div-Misc-MortgageDocs{
    vertical-align: text-bottom !important;
}
